.block-ui {
   position: absolute;
   z-index: 1000;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   height: 100%;
   width: 100%;
   min-height: 2em;
   cursor: wait;
   overflow: hidden;
}

.block-ui-container:focus {
   outline: none;
}

.block-ui-overlay {
   width: 100%;
   height: 100%;
   opacity: 0.2;
   filter: alpha(opacity=50);
   background-color: #000;
}

.block-ui-message-container {
   position: absolute;
   top: 50%;
   left: 0;
   right: 0;
   text-align: center;
   transform: translateY(-50%);
   z-index: 10001;
}

.block-ui-message {
   color: #333;
   background: none;
   z-index: 1011;
}

.block-ui-loader {
   position: absolute;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10000;
}

.block-ui-loader>span {
   padding: 0.5rem 0.75rem;
   background-color: #efefef;
   border-radius: 5px;
   font-size: 0.8rem;
}
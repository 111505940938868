.Toastify__toast-body {
   font-size: 0.8rem !important;
}

.cpcs-tabs .nav-tabs {
   background: none !important;
   border: none !important;
   border-bottom: solid 2px #0078D7 !important;
   border-radius: 0 !important;
}

.cpcs-tabs .nav-tabs .nav-link,
.cpcs-tabs .nav-tabs .nav-link:hover,
.cpcs-tabs .nav-tabs .nav-link:focus {
   border: none !important;
   font-weight: 400 !important;
   padding: 0.5rem 1rem !important;
   margin: 0 !important;
   color: #343f52 !important;
}

.cpcs-tabs .nav-tabs .nav-link.active {
   font-weight: 700 !important;
   border: none !important;
   background: #fff !important;
   color: #0078D7 !important;
}

.cpcs-tabs .tab-content {
   margin: 0;
   padding: 1rem;
}

.cpcs-tabs .tab-content .badge {
   padding: 5px 10px;
   background: #ccc;
   margin-right: 5px;
   border-radius: 5px;
   border: solid 1px #aaa;
   font-size: 0.8rem;
}

.cpcs-link,
.cpcs-link:hover,
.cpcs-link:focus {
   font-weight: 700;
   color: #212529;
   text-decoration: none !important;
}

.cpcs-link:hover,
.cpcs-link:focus {
   text-decoration: underline !important;
}

.cpcs-list-item {
   display: inline-block;
   margin-right: 1.5rem;
   font-size: 0.875rem;
}

.accordion-button {
   font-weight: 400;
   padding: 0.6rem 0.6rem 0.6rem 1rem;
}

.accordion-body {
   padding: 0.6rem 0.6rem 0.6rem 1rem;
}

.form-select-sm{
   padding-right: 1.5rem !important;
}

.flu-title{
   font-size: 0.875rem;
}

.input-group input.btn{
   min-height: calc(3.5rem + 2px);
}

.navbar-collapse.offcanvas{
   background-color: #1e2228 !important;
}

@media (min-width: 992px) {
   .navbar-collapse.offcanvas{
      background-color: transparent !important;
   }
}
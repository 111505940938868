.videocall {
   width: 100%;
   height: 600px;
   position: relative;
}

.videocall .localVideocall {
   width: 100%;
   height: calc(100% - 66px) !important;
   background-color: #000;
   position: absolute;
   top: 66px;
}

.videocall .smallImage {
   width: 180px;
   height: 250px;
   position: absolute;
   left: 40px;
   bottom: 5%;
}

.videocall .media.videocallDetails {
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   height: 66px;
   padding: 35px;
   align-items: center;
   background-color: #ccc !important;
}

.videocall .videocallDetails .usersprof {
   margin-right: 15px;
   display: flex;
   margin-left: 15px;
}

.videocall .videocallDetails .usersprof .profile {
   margin-left: -15px;
}

.media {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.profile {
   width: 45px;
   height: 45px;
   display: inline-block;
   position: relative;
}

.profile span {
   font-size: 22px;
   position: absolute;
   top: 50%;
   left: 50%;
   margin-right: -50%;
   transform: translate(-50%, -50%);
   font-weight: 600;
   color: #ffffff;
}

.profile div.online {
   background-color: #3fcc35;
   border: 1px solid #fff;
   border-radius: 50%;
   position: absolute;
   width: 10px;
   height: 10px;
   top: -3px;
   right: -3px;
}

.mediaBody {
   flex: 1 1;
}

h3 {
   font-family: "Montserrat", sans-serif !important;
   font-size: calc(16px + 2 * (100vw - 320px) / 1600) !important;
   font-weight: 700 !important;
   color: #223645 !important;
   margin: 0 !important;
}

#basicUsage {
   font-size: 16px;
   color: #000000;
   font-family: "Montserrat", sans-serif;
   font-weight: 700;
}

.videocall .videocallDetails .zoomcontent {
   width: 30px !important;
   height: 30px !important;
   border: 1px solid rgba(0, 0, 0, 0.175);
}

.videocall .videocallDetails .zoomcontent {
   border-radius: 50%;
   background-color: #ffffff;
   padding: 7px;
}

.videocall .videocallDetails .zoomcontent a svg {
   width: 15px !important;
   height: 15px !important;
   margin-top: -20px;
}

svg {
   overflow: hidden;
   vertical-align: middle;
}

.videocall .centerCon {
   z-index: 10;
   position: absolute;
   bottom: 40px;
   width: 100%;
}

.videocall ul {
   display: flex;
   justify-content: center;
   align-items: center;
}

ul {
   padding-left: 0;
   list-style-type: none;
   margin-bottom: 0;
   padding-right: 0;
}

.videocall ul li+li {
   margin-left: 30px;
}

.buttonEffect {
   display: inline-flex;
   overflow: hidden;
   position: relative;
}

.iconBtn {
   height: 42px;
   width: 42px;
   border-radius: 100%;
   font-weight: 800;
   align-items: center;
   justify-content: center;
   display: inline-flex;
   border: 0 !important;
}

.videocall .centerCon .pause,
.videocall .centerCon .mic,
.videocall .centerCon .phone {
   box-shadow: none;
}

.videocall .centerCon .iconBtn {
   border: 1px solid rgba(0, 0, 0, 0.175);
}

.iconBtn.phone {
   height: 60px;
   width: 60px;
   padding: 18px;
}

.iconBtn svg {
   width: 16px;
   height: 16px;
   stroke-width: 3px;
   vertical-align: middle;
}

.iconBtn.phone svg {
   height: 24px;
   width: 24px;
}

.videocall.active {
   height: 100vh !important;
   width: 100vw !important;
}

.videocall.active {
   z-index: 2000;
   position: fixed !important;
   top: 0 !important;
   left: 0 !important;
   height: 100vh !important;
   width: 100vw !important;
}

.videocall.active .localVideocal {
   height: calc(100vh - 66px) !important;
   width: 100vw !important;
}

.videocall .videocallDetails .zoomcontent .dropdown .dropdownMenu {
   padding-top: 0.35rem;
   padding-bottom: 0.35rem;
}

.videocall .videocallDetails .zoomcontent .dropdown .dropdownMenu .dropdownItem {
   display: flex !important;
   align-items: center !important;
   gap: 0.5rem !important;
   font-size: 0.75rem !important;
   padding: 0.15rem 0.5rem !important;
}

.videocall .videocallDetails .zoomcontent .dropdown .dropdownMenu .dropdownItem svg {
   width: 15px !important;
   height: 15px !important;
   margin-top: 0.1rem !important;
}

@media (max-width: 576px) {
   .videocall .media.videocallDetails {
      overflow-x: scroll;
   }
}